<template>
    <section v-if="company" class="admin-section">
        <h2>{{ $t('My Subscription') }}</h2>
        <div class="margin-bottom--big">
            <h3 class="margin-bottom">{{ $t('Product') }}</h3>
            <SubscriptionTypeBoxesAlt :company-category="company.category" />
        </div>
        <div v-if="isUserAdmin">
            <company-category-form v-if="company"
                                   :company="company"
                                   @saved="fetch">
                <template #default="{on}">
                    <div class="margin-bottom--big margin-top">
                        <h3 class="mb-2">{{ $t('Category') }}</h3>
                        <p>
                            <span class="tag is-black is-large" style="height: 58px; width: 58px">{{ company.category }}</span>
                            <button class="button is-primary tag-align-button"
                                    @click="on">
                                <span>{{ $t('Change category') }}</span>
                            </button>
                        </p>
                    </div>
                </template>
            </company-category-form>
        </div>
    </section>
</template>

<script>
    import ApiService from '@/services/api.service'
    import { NotificationMixin } from '@/config/mixins'
    import SubscriptionTypeBoxesAlt from '@/components/SubscriptionTypeBoxesAlt'
    import { mapState } from 'vuex'
    import CompanyCategoryForm from './CompanyCategoryForm'

    export default {
        name: 'MyProfile',
        components: {
            CompanyCategoryForm,
            SubscriptionTypeBoxesAlt
        },
        mixins: [ NotificationMixin ],
        data() {
            return {
                company: null,
                form: { key: null },
                loading: false
            }
        },
        computed: {
            ...mapState('auth', [ 'user' ]),
            isUserAdmin() {
                return this.user.owner_of_company.length > 0
            }
        },
        async mounted() {
            await this.fetchCompany()
        },
        methods: {
            async fetch() {
                await this.fetchCompany()
            },
            async fetchCompany() {
                this.company = (await ApiService.get('/companies/company/')).data[0]
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "~@/assets/vars.scss";

.text--red {
    color: red;
    font-weight: bold;
}

.btn-vertical-align {
    margin-top: 1.7rem
}

h3 {
    font-size: 1.2rem;
    font-weight: $weight-semibold;
}

.margin-bottom {
    margin-bottom: 20px;
}

.margin-top {
    margin-top: 20px;
}

.margin-bottom--big {
    margin-bottom: 40px;
}

.field--validity {
    font-weight: bold;
}

.field--validity /deep/ .label {
    display: inline;
}

.tag-align-button {
    margin-left: 10px;
}

.subscriptions {
    justify-content: left;

    & /deep/ .subscription-box {
        margin-right: 60px;
    }
}

.modal-card {
    width: 480px
}

form {
    max-width: 100%;
}

</style>
