<template>
    <section class="admin-section">
        <h2>{{ $t('My Profile') }}</h2>

        <template v-if="!editMode">
            <div class="columns">
                <div class="column is-narrow">
                    <AvatarIcon class="avatar" />
                </div>
                <div class="column">
                    <div class="columns is-multiline readonly-fields">
                        <b-field class="column is-half-desktop" :label="$t('Salutation')">
                            {{ $t(salutations.$label(user.salutation)) }}
                        </b-field>
                        <b-field class="column is-half-desktop" :label="$t('E-mail')">
                            {{ user.email }}
                        </b-field>
                        <b-field class="column is-half-desktop" :label="$t('First name')">
                            {{ user.first_name }}
                        </b-field>
                        <b-field class="column is-half-desktop" :label="$t('Last name')">
                            {{ user.last_name }}
                        </b-field>
                        <b-field class="column is-half-desktop" :label="$t('Password')">
                            ********
                        </b-field>
                    </div>
                </div>
            </div>

            <div class="buttons" style="justify-content: space-between">
                <b-button class="is-dark" style="margin-left: auto" @click="editRecord">
                    {{ $t('Edit details') }}
                </b-button>
            </div>
        </template>

        <div v-else class="columns">
            <div class="column is-narrow">
                <AvatarIcon class="avatar" />
            </div>
            <div class="column">
                <form action="" novalidate>
                    <b-field :label="$t('E-mail')">
                        {{ user.email }}
                    </b-field>
                    <b-field :label="$t('Salutation')">
                        <b-select v-model="model.salutation" required
                                  :validation-message="$t('Please fill out this field')">
                            <option v-for="option in salutations"
                                    :key="option.key" :value="option.key">
                                {{ $t(option.label) }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field :label="$t('First name')">
                        <b-input v-model="model.first_name" required name="first_name"
                                 :validation-message="$t('Please fill out this field')" />
                    </b-field>
                    <b-field :label="$t('Last name')">
                        <b-input v-model="model.last_name" required name="last_name"
                                 :validation-message="$t('Please fill out this field')" />
                    </b-field>

                    <b-field :label="$t('Current password')">
                        <b-input v-model="model.currentpassword"
                                 required
                                 name="currentpassword"
                                 type="password"
                                 :validation-message="$t('Please fill out this field')" />
                    </b-field>
                    <b-field :label="$t('Password')">
                        <b-input v-model="model.password" autocomplete="new-password"
                                 required
                                 name="password"
                                 type="password"
                                 :validation-message="$t('Please fill out this field')" />
                    </b-field>
                    <b-field :label="$t('Password confirmation')">
                        <b-input v-model="model.password2" autocomplete="new-password"
                                 required
                                 name="password2"
                                 type="password"
                                 :validation-message="$t('Please fill out this field')" />
                    </b-field>
                    <div class="buttons" style="justify-content: center; margin-top: 3rem">
                        <b-button class="is-dark" @click="cancelEdit()">
                            {{ $t('Cancel') }}
                        </b-button>
                        <b-button class="is-primary" @click="save()">
                            {{ $t('Save changes') }}
                        </b-button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
    import ApiService from '@/services/api.service'
    import AvatarIcon from '@/components/icons/Avatar'
    import { salutations } from '@/modules/Registration/consts'
    import cloneDeep from 'lodash/cloneDeep'
    import { mapState } from 'vuex'
    import { NotificationMixin } from '@/config/mixins'

    export default {
        name: 'MyProfile',
        components: { AvatarIcon },
        mixins: [ NotificationMixin ],
        data() {
            return {
                model: {},
                editMode: false,

                AvatarIcon,
                salutations
            }
        },
        computed: { ...mapState('auth', [ 'user' ]) },
        watch: {
            editMode(value) {
                this.$emit('editMode', value)
            }
        },
        methods: {
            editRecord() {
                this.model = cloneDeep(this.user)
                this.editMode = true
            },
            cancelEdit() {
                this.editMode = false
            },
            async save() {
                const data = {
                    first_name: this.model.first_name,
                    last_name: this.model.last_name,
                    salutation: this.model.salutation,
                    password: this.model.password || undefined,
                    password2: this.model.password2 || undefined,
                    currentpassword: this.model.currentpassword || undefined
                }
                try {
                    await ApiService.patch(`/user/user/${this.model.id}/`, data)
                    await this.$store.dispatch('auth/fetchUser')
                    this.notifySuccess()
                    this.cancelEdit()
                } catch (e) {
                    let error = e.response ? e.response.data || e.response : e
                    if (typeof error === 'object')
                        error = Object.values(error).join(', ')

                    this.notifyError(error)
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
.avatar {
    width: 70px;
    height: auto;
    margin-left: .5vw;
    margin-right: 3vw;
}

.editModal /deep/ .animation-content {
    min-width: 600px;
}
</style>
