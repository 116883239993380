<template>
    <section class="admin-section">
        <h2>{{ $t('User Management') }}</h2>

        <template v-if="!editMode">
            <b-table hoverable :loading="loading" :data="employees">
                <template slot-scope="{row}">
                    <b-table-column :label="$t('Name')">
                        {{ row.first_name }} {{ row.last_name }}
                        <br>
                        <span style="text-decoration: underline">{{ row.email }}</span>
                    </b-table-column>
                    <b-table-column field="function" :label="$t('Function')" width="200">
                        {{ row.function }}
                    </b-table-column>
                    <b-table-column field="role" :label="$t('Role')" width="200">
                        {{ roleOptions.$label(row.role) }}
                        <span v-if="!row.owner_of_company || row.owner_of_company.length">({{ $t("Owner") }})</span>
                    </b-table-column>
                    <b-table-column :label="$t('Actions')"
                                    style="vertical-align: middle; white-space: nowrap" width="100">
                        <a :title="$t('Edit')" style="margin: 0 10px" @click="editItem(row)">
                            <EditIcon />
                        </a>
                        <a v-if="user.id !== row.id && ( !row.owner_of_company || row.owner_of_company.length === 0 )"
                           :title="$t('Delete')"
                           style="margin: 0 10px"
                           @click="deleteItem(row)">
                            <DeleteIcon />
                        </a>
                        <a v-if="user.id !== row.id"
                           class="send-invite-button"
                           :title="$t('Send invitation by email')"
                           style="margin: 0 10px; white-space: nowrap; font-size: .9em;"
                           @click="sendInvite(row)">
                            <b-icon icon="email-send" />
                        </a>
                        <span class="send-invite-button" :title="$t('Invitation already sent')">
                            <b-icon v-if="row.invitation_sent" icon="email-check-outline"
                                    class="no-hover" />
                        </span>
                    </b-table-column>
                </template>
            </b-table>

            <div class="buttons" style="justify-content: flex-end; margin-top: 1.5rem;">
                <b-button class="is-dark" @click="newItem()">
                    {{ $t('New entry') }}
                </b-button>
            </div>
        </template>

        <form v-else ref="form" action="" novalidate>
            <b-field grouped>
                <b-field :label="$t('First name')" expanded>
                    <b-input v-model="model.first_name" required
                             :validation-message="$t('Please fill out this field')"
                             name="first_name" />
                </b-field>
                <b-field :label="$t('Last name')" expanded>
                    <b-input v-model="model.last_name" required
                             :validation-message="$t('Please fill out this field')"
                             name="last_name" />
                </b-field>
            </b-field>
            <b-field :label="$t('Function')">
                <b-input v-model="model.function" required
                         :validation-message="$t('Please fill out this field')"
                         name="function" />
            </b-field>
            <b-field :label="$t('E-mail')">
                <b-input v-model="model.email" required
                         :validation-message="$t('Please fill out this field')"
                         type="email" name="email" />
            </b-field>
            <b-field v-if="!model.owner_of_company || model.owner_of_company.length === 0"
                     :label="$t('Role')">
                <b-select v-model="model.role">
                    <option v-for="option in roleOptions" :key="option.key"
                            :value="option.key">
                        {{ option.label }}
                    </option>
                </b-select>
            </b-field>
            <b-field v-else :label="$t('Role')">
                <span>{{ roleOptions.$label(model.role) }} (Owner)</span>
            </b-field>

            <div class="buttons" style="justify-content: center">
                <b-button class="is-dark" @click="cancelEdit()">
                    {{ $t('Cancel') }}
                </b-button>
                <b-button class="is-primary" @click="save()">
                    {{ $t('Save changes') }}
                </b-button>
            </div>
        </form>
    </section>
</template>

<script>
    import { roleOptions } from '@/modules/Registration/consts'
    import cloneDeep from 'lodash/cloneDeep'
    import DeleteIcon from '@/components/icons/Delete'
    import EditIcon from '@/components/icons/Edit'
    import ApiService from '@/services/api.service'
    import { NotificationMixin } from '@/config/mixins'
    import { mapState } from 'vuex'

    export default {
        name: 'UserManagement',
        components: { DeleteIcon, EditIcon },
        mixins: [ NotificationMixin ],
        data() {
            return {
                employees: [],
                roleOptions,

                editMode: false,
                model: null,
                loading: false
            }
        },
        computed: { ...mapState('auth', [ 'user' ]) },
        watch: {
            editMode(value) {
                this.$emit('editMode', value ? this.model : false)
            }
        },
        created() {
            this.fetch()
        },
        methods: {
            async fetch() {
                this.loading = true
                this.employees = (await ApiService.get(`/user/company_user/`)).data
                    .sort((a, b) => this.roleOptions.findIndex(role => role.key === a.role) - this.roleOptions.findIndex(role => role.key === b.role))
                this.loading = false
            },
            editRecord(newModel) {
                this.model = newModel
                this.editMode = true
            },
            editItem(item) {
                this.model = cloneDeep(item)
                this.editMode = true
            },
            newItem() {
                this.model = { role: 'display' }
                this.editMode = true
            },
            async deleteItem(item) {
                try {
                    await ApiService.delete(`/user/company_user/${item.id}/`)
                    this.notifySuccess(this.$i18n.t('Successfully deleted.'))
                    this.fetch()
                } catch (e) {
                    this.notifyError(e.response ? e.response.data || e.response : e)
                }
            },
            cancelEdit() {
                this.editMode = false
            },
            async save() {
                if (!this.$refs.form.reportValidity())
                    return

                try {
                    this.model.id
                        ? await ApiService.put(`/user/company_user/${this.model.id}/`, this.model)
                        : await ApiService.post('/user/company_user/', this.model)
                    this.notifySuccess()
                    this.fetch()
                    this.cancelEdit()
                } catch (e) {
                    this.notifyError(e.response ? e.response.data || e.response : e)
                }
            },
            async sendInvite(record) {
                try {
                    await ApiService.post(`/user/company_user/${record.id}/send_invite/`)
                    this.notifySuccess(this.$i18n.t('Invite successfully sent.'))
                    this.fetch()
                } catch (e) {
                    this.notifyError(e.response ? e.response.data || e.response : e)
                }
            }
        }
    }
</script>

<style lang="scss">
@import "~@/assets/vars";

.admin-section {
    .table {
        font-size: .9rem;

        td, th {
            border-bottom-width: 1px;
            border-color: $light-grey;
            padding: 0;
            height: 42px;
            vertical-align: middle;
        }
    }
}

.send-invite-button {
    color: #797979;

    .icon {
        line-height: 1;
    }

    .mdi:before {
        font-size: 18px !important;
        vertical-align: bottom;
    }
}

.subscription-box:not(.is-active) {
    border-color: $light-grey !important;
}
</style>
