<template>
    <div>
        <slot :on="step1" />
        <b-modal :active.sync="isActive"
                 has-modal-card
                 trap-focus
                 :destroy-on-hide="false"
                 aria-role="dialog"
                 class
                 aria-modal
                 :on-cancel="cancel"
        >
            <div class="modal-card">
                <template v-if="step === 1">
                    <ValidationObserver v-slot="{ invalid }">
                        <form @submit.prevent="step2">
                            <header class="modal-card-head">
                                <p class="modal-card-title">
                                    {{ $t('Heads up!') }}
                                </p>
                            </header>
                            <section class="modal-card-body">
                                {{ $t(`Are you sure you want to change operating category?`) }}
                            </section>
                            <footer class="modal-card-foot">
                                <div class="card-footer-item">
                                    <button class="button" type="button"
                                            @click="isActive = false">
                                        {{ $t('Cancel') }}
                                    </button>
                                </div>
                                <div class="card-footer-item">
                                    <button class="button is-primary"
                                            native-type="submit"
                                            :loading="loading"
                                            :disabled="invalid">
                                        {{ $t('Change category') }}
                                    </button>
                                </div>
                            </footer>
                        </form>
                    </ValidationObserver>
                </template>
                <template v-if="step === 2">
                    <ValidationObserver v-slot="{ invalid }">
                        <form @submit.prevent="step3">
                            <header class="modal-card-head">
                                <p class="modal-card-title">
                                    {{ $t('Select your new category') }}
                                </p>
                            </header>
                            <section class="modal-card-body">
                                <b-field :label="$t('Current category')">
                                    {{ company.category }}
                                </b-field>
                                <b-field :label="$t('Desired category')">
                                    <ValidationProvider name="key"
                                                        rules="required">
                                        <b-select
                                            v-model="form.category"
                                            :placeholder="$t('Select a desired category')"
                                            required
                                            expanded>
                                            <template v-for="option in ['A', 'B', 'C', 'D']">
                                                <option v-if="option !== company.category"
                                                        :key="option"
                                                        :value="option">
                                                    {{ option }}
                                                </option>
                                            </template>
                                        </b-select>
                                    </ValidationProvider>
                                </b-field>
                            </section>
                            <footer class="modal-card-foot">
                                <div class="card-footer-item">
                                    <button class="button" type="button"
                                            @click="isActive = false">
                                        {{ $t('Cancel') }}
                                    </button>
                                </div>
                                <div class="card-footer-item">
                                    <button class="button is-primary"
                                            native-type="submit"
                                            :loading="loading"
                                            :disabled="invalid">
                                        {{ $t('Change category') }}
                                    </button>
                                </div>
                            </footer>
                        </form>
                    </ValidationObserver>
                </template>
                <template v-if="step === 3">
                    <ValidationObserver v-slot="{ invalid }">
                        <form @submit.prevent="submit">
                            <header class="modal-card-head">
                                <p class="modal-card-title">
                                    {{ $t('Heads up!') }}
                                </p>
                            </header>
                            <section class="modal-card-body">
                                {{ $t(`Are you sure you want to change operating category?`) }}
                                <br>
                                {{ $t(`You will have to login again.`) }}
                            </section>
                            <footer class="modal-card-foot">
                                <div class="card-footer-item">
                                    <button class="button" type="button"
                                            @click="cancel">
                                        {{ $t('Cancel') }}
                                    </button>
                                </div>
                                <div class="card-footer-item">
                                    <button class="button is-primary"
                                            native-type="submit"
                                            :disabled="invalid">
                                        {{ $t('Change operating category') }}
                                    </button>
                                </div>
                            </footer>
                        </form>
                    </ValidationObserver>
                </template>
                <template v-if="step === 4">
                    <header class="modal-card-head">
                        <p class="modal-card-title">
                            {{ $t('Category successful changed') }}
                        </p>
                    </header>
                    <section class="modal-card-body">
                        {{ $t(`The category has been updated.`) }}
                        <br>
                        {{ $t(`You will be logged out now.`) }}
                    </section>
                    <footer class="modal-card-foot">
                        <div class="card-footer-item">
                            <button class="button is-primary" @click="cancel">
                                {{ $t('Ok') }}
                            </button>
                        </div>
                    </footer>
                </template>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import ApiService from '@/services/api.service'
    import { mapActions } from 'vuex'

    export default {
        name: 'CompanyCategoryForm',
        components: {},
        props: {
            company: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                isActive: false,
                loading: false,
                step: 0,
                form: { category: '' }
            }
        },
        methods: {
            ...mapActions('auth', [ 'logout' ]),
            cancel() {
                if (this.step === 4)
                    this.logout()

                this.isActive = false
                this.step = 0
                this.$emit('cancel')
            },
            step1() {
                this.step = 1
                this.isActive = true
            },
            step2() {
                this.form.category = ''
                this.step = 2
            },
            step3() {
                this.step = 3
            },
            async submit() {
                this.loading = true
                try {
                    const response = await ApiService.post('/companies/company/change_category/', this.form)
                    if (response.data.success) {
                        this.step = 4
                        this.$emit('saved')
                    }
                } catch (e) {
                    this.$buefy.dialog.alert({ message: e })
                }
                this.loading = false
            }

        }
    }
</script>

<style lang="scss" scoped>

.modal-card-body {
    height: 200px;
}

.card-footer-item {
    padding: 0 0.5rem;
    border-right: none;

    & > button {
        flex: auto;
    }
}
</style>
