<template>
    <section id="admin" class="section">
        <div class="container container--administration" :class="{isEditModeActive}">
            <template v-if="!isEditModeActive">
                <b-collapse v-for="section in sections" :key="section.name"
                            class="accordion"
                            :class="{'trigger-is-hidden': isEditModeActive && isOpen !== section.name}"
                            animation="slide" :open="checkOpen(section)"
                            @open="openSection(section)">
                    <div v-if="hasRole(section.roles)" slot="trigger"
                         slot-scope="{ open }"
                         :class="{ 'accordion-header': true, open: checkOpen(section) }"
                         role="button">
                        <a class="accordion-header-icon mr-2 mt-2">
                            <b-icon :icon="open ? 'chevron-up' : 'chevron-down'" />
                        </a>
                        <h2 class="accordion-header-title">{{ section.title }}</h2>
                    </div>
                    <div
                        v-if="isOpen === section.name && section.component && hasRole(section.roles)"
                        :class="{ 'accordion-content': true, open: section.open }">
                        <portal-target name="section-content" />
                    </div>
                </b-collapse>
            </template>
            <div v-else class="accordion">
                <div class="accordion-header open">
                    <h2 class="accordion-header-title">{{ activeSectionObject.title }}</h2>
                </div>
                <div class="accordion-content open">
                    <portal-target name="solo" />
                </div>
            </div>
            <portal :to="isEditModeActive ? 'solo' : 'section-content'" slim>
                <component :is="activeSectionObject.component" ref="activeComponent" @editMode="onEditModeChange" />
            </portal>
        </div>
    </section>
</template>

<script>
    import MyProfile from './components/MyProfile'
    import MyBusiness from './components/MyBusiness'
    import MySubscription from './components/MySubscription'
    import UserManagement from './components/UserManagement'
    import { hasRole } from '@/modules/Auth/helpers'

    export default {
        name: 'Administration',
        beforeRouteUpdate(to, from, next) {
            if (to.name.indexOf('administration.') > -1)
                this.isOpen = to.name.split('.').slice(-1)[ 0 ]
            next()
        },
        data() {
            return {
                sections: [
                    { name: 'profile', title: this.$t('My Profile'), component: MyProfile },
                    { name: 'business', title: this.$t('My Business'), component: MyBusiness },
                    {
                        name: 'subscription',
                        title: this.$t('My Subscription'),
                        component: MySubscription,
                        roles: [ 'administrator' ]
                    },
                    {
                        name: 'users',
                        title: this.$t('User management'),
                        component: UserManagement,
                        roles: [ 'administrator' ]
                    }
                ],
                isOpen: null,
                isEditModeActive: false
            }
        },
        computed: {
            activeSection() {
                return this.$route.name.split('.').slice(-1)[ 0 ]
            },
            activeSectionObject() {
                return this.sections.find(section => section.name === this.activeSection)
            }
        },
        created() {
            this.isOpen = this.activeSection
        },
        methods: {
            openSection(section) {
                if (this.activeSection !== section.name)
                    this.$router.replace({ name: `administration.${section.name}` })
            },
            checkOpen(section) {
                return this.isOpen === section.name
            },
            hasRole,
            onEditModeChange(payload) {
                this.isEditModeActive = Boolean(payload)
                this.$nextTick(() => {
                    if (this.isEditModeActive)
                        this.$refs.activeComponent.editRecord(payload)
                    else
                        this.$refs.activeComponent.cancelEdit()
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@/assets/vars.scss';

.container--administration{
    background-color: $white;
    padding: 32px 40px;

    .accordion {
        padding-bottom: 0;
    }
    .accordion-header {
        border-color: $light-grey;
        height: 70px;
        justify-content: left;
        &.open h2{
            font-weight: normal;
        }
    }

}

.accordion.trigger-is-hidden {
    padding-bottom: 0;

    /deep/ .collapse-trigger {
        display: none;
    }
}

.isEditModeActive {
    /deep/ .collapse-trigger {
        &, * {
            pointer-events: none;
        }

        .accordion-header-icon {
            display: none
        }
    }
}
</style>
