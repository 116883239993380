<template>
    <div class="columns subscriptions is-marginless">
        <div class="column subscription-box subscription-case"
             :class="{'is-active':['C','D'].includes(companyCategory)}">
            <div class="row-1">
                <LicenceCompactIcon />
                <div class="subscription-title">
                    {{ $t('Compact') }}
                </div>
                <div class="subscription-subtitle">
                    {{ $t('Licence for small businesses') }}
                </div>
            </div>
            <div class="row-2">
                {{ $t('Business category C & D') }}
                <br>
                {{ $t('according to the categorisation GVG') }}
            </div>
        </div>
        <div class="column subscription-box subscription-case"
             :class="{'is-active':['A','B'].includes(companyCategory)}">
            <div class="row-1">
                <LicenceCompleteIcon />
                <div class="subscription-title">
                    {{ $t('Complete') }}
                </div>
                <div class="subscription-subtitle">
                    {{ $t('Licence for medium and big businesses') }}
                </div>
            </div>
            <div class="row-2">
                {{ $t('Business category A & B') }}
                <br>
                {{ $t('according to the categorisation GVG') }}
            </div>
        </div>
    </div>
</template>

<script>

    import LicenceCompactIcon from '@/components/icons/LicenceCompact'
    import LicenceCompleteIcon from '@/components/icons/LicenceComplete'

    export default {
        name: 'SubscriptionTypeBoxesAlt',
        components: {
            LicenceCompactIcon,
            LicenceCompleteIcon
        },
        props: { companyCategory: { type: String, required: true } }
    }
</script>

<style lang="scss" scoped>
@import "~@/assets/vars.scss";

.subscriptions {
    font-size: .9rem;
    text-align: center;
    justify-content: center;

    .subscription-box {
        max-width: 230px;
        background-color: $white;
        border: 2px solid $white;
        display: flex;
        flex-direction: column;

        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.02em;

        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        padding: 0;

        .row-1 {
            background-color: $lightest-grey;
            height: 186px;
        }

        &.subscription-header {
            box-shadow: none;

            .row-1 {
                padding-top: 137px;
            }
        }

        &.subscription-case {
            .row-1 {
                background-color: $light-grey;
                padding-top: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                justify-items: center;
                align-content: center;
                align-items: center;
            }
        }

        .row-2 {
            background-color: $white;
            height: 80px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
        }

        .row-3 {
            background-color: $lightest-grey;
            height: 60px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
        }

        .row-4 {
            background-color: $white;
            height: 60px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
        }

        &.subscription-header {
            min-width: 160px;
        }


        &.is-active {
            border-color: $primary;
        }

        .subscription {
            &-title {
                font-size: 16px;
                line-height: 26px;
                letter-spacing: 0.05em;
                font-weight: 500;
                background-color: $lightest-grey;
                padding: 4px 16px;
                display: inline-block;
                margin-top: 18px;
            }

            &-subtitle {
                display: block;
                line-height: 20px;
                margin: 8px 20px 0;
            }
        }
    }

    .subscription-box + .subscription-box {
        margin-left: 8px;
    }
}

</style>
