<template>
    <section v-if="company" class="admin-section">
        <h2>{{ $t('My Business') }}</h2>

        <template v-if="!editMode">
            <div class="columns is-multiline readonly-fields" style="padding-left: 2rem">
                <b-field class="column is-one-third-desktop" :label="$t('Company name')">
                    {{ company.name }}
                </b-field>
                <b-field class="column is-one-third-desktop" :label="$t('Address')">
                    {{ company.address }}
                </b-field>
                <b-field class="column is-one-third-desktop" :label="$t('Postcode and City')">
                    {{ company.postcode }}
                    {{ company.city }}
                </b-field>
                <b-field class="column is-one-third-desktop" :label="$t('Telephone')">
                    {{ company.telephone }}
                </b-field>
                <b-field class="column is-one-third-desktop" :label="$t('E-mail')">
                    {{ company.email }}
                </b-field>
                <b-field class="column is-one-third-desktop" :label="$t('Interface language')">
                    {{ availableLanguages.$label(company.language) }}
                </b-field>

                <b-field class="column is-one-third-desktop" :label="$t('Category')">
                    {{ company.category }}
                </b-field>
            </div>

            <div v-if="hasRole('administrator')" class="buttons"
                 style="justify-content: space-between">
                <b-button class="is-dark" style="margin-left: auto" @click="editRecord">
                    {{ $t('Edit details') }}
                </b-button>
            </div>
        </template>

        <form v-else action="" novalidate>
            <b-field :label="$t('Company name')">
                <b-input v-model="model.name" required name="name"
                         :validation-message="$t('Please fill out this field')" />
            </b-field>
            <b-field :label="$t('Address')">
                <b-input v-model="model.address" required name="address"
                         :validation-message="$t('Please fill out this field')" />
            </b-field>
            <b-field grouped>
                <b-field :label="$t('Postcode')">
                    <b-input v-model="model.postcode" required name="postcode"
                             :validation-message="$t('Please fill out this field')" />
                </b-field>
                <b-field :label="$t('City')" expanded>
                    <b-input v-model="model.city" required name="city"
                             :validation-message="$t('Please fill out this field')" />
                </b-field>
            </b-field>
            <b-field :label="$t('Telephone')">
                <b-input v-model="model.telephone" name="telephone" />
            </b-field>
            <b-field :label="$t('E-mail')">
                <b-input v-model="model.email" type="email" name="email" />
            </b-field>

            <b-field required :label="$t('Company category')" :addons="false"
                     class="radio-label" :validation-message="$t('Please fill out this field')">
                <b-radio v-for="category in ['A','B','C','D']" :key="category"
                         v-model="model.category"
                         :disabled="true" :native-value="category"
                         size="is-small">
                    {{ category }}
                </b-radio>
            </b-field>

            <b-field :label="$t('Interface language')">
                <b-select v-model="model.language" required
                          :validation-message="$t('Please fill out this field')">
                    <option v-for="option in availableLanguages"
                            :key="option.key"
                            :value="option.key">
                        {{ $t(option.label) }}
                    </option>
                </b-select>
            </b-field>

            <div class="buttons" style="justify-content: center">
                <b-button class="is-dark" @click="cancelEdit()">
                    {{ $t('Cancel') }}
                </b-button>
                <b-button class="is-primary" @click="save()">
                    {{ $t('Save changes') }}
                </b-button>
            </div>
        </form>
    </section>
</template>

<script>
    import cloneDeep from 'lodash/cloneDeep'
    import { NotificationMixin } from '@/config/mixins'
    import { mapState } from 'vuex'
    import { hasRole } from '@/modules/Auth/helpers'
    import { languageOptions } from '@/modules/Registration/consts'

    export default {
        name: 'MyBusiness',
        mixins: [ NotificationMixin ],
        data() {
            return {
                model: {},
                editMode: false,
                availableLanguages: languageOptions
            }
        },
        computed: { ...mapState('administration', { company: (state) => state.businesses.items.length > 0 ? state.businesses.items[0] : null }) },
        watch: {
            editMode(value) {
                this.$emit('editMode', value)
            }
        },
        mounted() {
            this.$store.dispatch('administration/fetchBusinesses')
        },
        methods: {
            hasRole,
            editRecord() {
                this.model = cloneDeep(this.company)
                this.editMode = true
            },
            cancelEdit() {
                this.editMode = false
            },
            async save() {
                try {
                    await this.$store.dispatch('administration/saveBusiness', this.model)
                    this.notifySuccess()
                    this.cancelEdit()
                } catch (e) {
                    this.notifyError(e.response ? e.response.data || e.response : e)
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
.editModal /deep/ .animation-content {
    min-width: 600px;
}
</style>
